import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import DataTable from '../../components/DataTable';
import AddEditFeedback from '../../components/popup/AddEditFeedback';
import { ActionButtonsForInterviewHistory, ResumeButton } from '../../components/ActionButtons';

// Prime-React Components
import { Badge } from 'primereact/badge';

// Constants
import { HISTORY_INTERVIEW_COLUMNS } from '../../constants/tableColumns';
import {
  ACTION_TYPE,
  DEBOUNCE_TIMEOUT,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  INTERVIEW_STATUS_HISTORY,
  INTERVIEW_TYPE,
  InterviewType,
  ROLES,
  STATUS_INTERVIEW,
  TABLE_NAME
} from '../../constants/common';

// Redux-Actions
import { getInterviewHistoryList } from '../../redux/actions/interviews';
import { interviewerFeedbackDetailAction } from '../../redux/actions/userDetails';

// Utils
import { formatTime } from '../../utils/date';
import { debounce } from '../../utils/debounce';
import { wordCapitalize, downloadPdf, isDarkMode, openResumeLink } from '../../utils/common';
import moment from 'moment';
import AddHrFeedback from '../../components/popup/AddHrFeedback';
import ReactTooltip from 'react-tooltip';

const pathBase = `${process.env.REACT_APP_API_ENDPOINT}/public/uploads/resume`;
const InterviewHistory = () => {
  // Redux hooks.
  const dispatch = useDispatch();
  const { interviewHistory, userDetails } = useSelector((state) => state);

  // React useState hooks.
  const [historyList, setHistoryList] = useState([]);
  const [historyExport, setHistoryExport] = useState([]);
  const [interviewRoundId, setInterviewId] = useState(null);
  const [viewFeedbackDetailsStatus, setViewFeedbackDetailsStatus] = useState(null);
  const [interviewRoundMode, setInterviewRoundMode] = useState(null);
  const [getCandidateId, setCandidateId] = useState(null);
  const [typeInterview, setTypeInterview] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showHRPopup, setShowHRPopup] = useState(false);
  const [getCandidateName, setCandidateName] = useState('');
  const [interviewCategory, setInterviewCategory] = useState(null);
  const [tableStates, setTableStates] = useState({
    searchValue: '',
    pageNumber: INITIAL_PAGE_NUMBER,
    size: INITIAL_SIZE,
    sortBy: 'DateModified',
    orderBy: 'desc'
  });

  const onTogglePopup = (value) => setShowPopup(value);
  const onToggleHRPopup = (value) => setShowHRPopup(value);

  // React useEffect hooks.
  useEffect(() => {
    dispatch(getInterviewHistoryList(tableStates));
  }, [dispatch, tableStates]);

  useEffect(() => {
    interviewRoundId && dispatch(interviewerFeedbackDetailAction(interviewRoundId));
  }, [dispatch, interviewRoundId]);

  useEffect(() => {
    const modifiedList = interviewHistory?.data?.map((data, index) => {
      const { InterviewRound } = data;
      const { Interview, Description } = InterviewRound;
      const candidateName = `${
        Interview?.Candidate?.FirstName ? wordCapitalize(Interview.Candidate?.FirstName) : ''
      } ${Interview?.Candidate?.LastName ? wordCapitalize(Interview.Candidate?.LastName) : ''}`;
      return {
        ...data,
        CandidateName: candidateName,
        HrName: `${
          InterviewRound.HROfRound?.FirstName
            ? wordCapitalize(InterviewRound.HROfRound?.FirstName)
            : ''
        } ${
          InterviewRound.HROfRound?.LastName
            ? wordCapitalize(InterviewRound.HROfRound?.LastName)
            : ''
        }`,

        'Start Date': `${InterviewRound.StartDateOfInterviewRound.substring(0, 10)} ${formatTime(
          InterviewRound.StartDateOfInterviewRound
        )}`,
        EndDateOfInterviewRound: `${InterviewRound.EndDateOfInterviewRound.substring(
          0,
          10
        )} ${formatTime(InterviewRound.EndDateOfInterviewRound)}`,
        Technology: (
          <ul className="technology-list">
            {Interview?.Candidate?.UMap && Interview?.Candidate?.UMap?.length > 0
              ? Interview.Candidate.UMap?.map((tech) => (
                  <li
                    key={tech.MapT.ID}
                    style={{
                      backgroundColor: `${
                        isDarkMode() ? tech?.MapT?.DarkModeColor : tech?.MapT?.TechnologyColor
                      }`
                    }}
                    className="technology-badge">
                    {tech.MapT.Technology}
                  </li>
                ))
              : []}
          </ul>
        ),
        JobDesignation:
          Interview?.JobDesignation && wordCapitalize(Interview?.JobDesignation?.Designation),
        RoundOfInterview: `${INTERVIEW_TYPE.filter(
          (num) => num.code === InterviewRound.InterviewRound
        ).map((type) => type.name)}`,
        Status: INTERVIEW_STATUS_HISTORY.filter((num) => num.code === InterviewRound?.Status).map(
          (type) => {
            return (
              <div className="center interview-status" key={index}>
                <Badge
                  key={type.code}
                  value={type.name}
                  className={`white-space-nowrap ${
                    InterviewRound?.Status === STATUS_INTERVIEW.CANCELLED
                      ? 'cancel-badge'
                      : 'complete-badge'
                  }`}
                />
                {InterviewRound?.Status === STATUS_INTERVIEW.CANCELLED &&
                  InterviewRound?.CancellationReason && (
                    <>
                      <i
                        className="custom-target-icon pi pi-info-circle pl-1"
                        data-tip
                        data-for={`reason-tooltip-${index}`}
                        key={index}
                        style={{ cursor: 'pointer' }}
                      />
                      <ReactTooltip key={index} id={`reason-tooltip-${index}`} aria-haspopup="true">
                        {(InterviewRound?.CancelledByUser?.FirstName ||
                          InterviewRound?.CancelledByUser?.LastName) && (
                          <p>{`Cancelled By: ${
                            wordCapitalize(InterviewRound?.CancelledByUser?.FirstName || '') +
                            ' ' +
                            wordCapitalize(InterviewRound?.CancelledByUser?.LastName || '')
                          } ${
                            InterviewRound?.CancelledByUser?.UserTypeId === ROLES.HR
                              ? '(HR)'
                              : InterviewRound?.CancelledByUser?.UserTypeId === ROLES.INTERVIEWER
                              ? '(Interviewer)'
                              : InterviewRound?.CancelledByUser?.UserTypeId ===
                                ROLES.HR_AND_INTERVIEWER
                              ? '(HR and Interviewer)'
                              : ''
                          }`}</p>
                        )}
                        <p>{`Cancellation Reason: ${
                          InterviewRound?.CancellationReason || '--'
                        }`}</p>
                      </ReactTooltip>
                    </>
                  )}
              </div>
            );
          }
        ),
        resume: (
          <div className="flex">
            {Interview?.Candidate?.ResumeOfCandidate && (
              <>
                <ResumeButton
                  onClick={() =>
                    openResumeLink(
                      `${pathBase}/${Interview?.Candidate?.ID}/${Interview?.Candidate?.ResumeOfCandidate?.FilePath}`
                    )
                  }
                />
                <i
                  data-tip="Download"
                  onClick={() =>
                    downloadPdf(
                      pathBase,
                      Interview?.Candidate?.ID,
                      Interview?.Candidate?.ResumeOfCandidate?.FilePath
                    )
                  }
                  className="pi pi-fw pi-download"
                />
              </>
            )}
          </div>
        ),
        Description: Description || '',
        action: InterviewRound?.Status !== STATUS_INTERVIEW.CANCELLED && (
          <div className="flex align-items-center">
            <ActionButtonsForInterviewHistory
              onClick={(actionType) =>
                handleRowActions(
                  actionType,
                  InterviewRound.ID,
                  InterviewRound?.InterviewRoundMode,
                  InterviewRound?.InterviewRound,
                  candidateName,
                  InterviewRound?.Interview?.Candidate?.ID,
                  InterviewRound?.Status,
                  Interview?.InterviewCategory
                )
              }
              disabled={moment(moment()).isBefore(
                InterviewRound.StartDateOfInterviewRound,
                'minute'
              )}
            />
          </div>
        )
      };
    });
    setHistoryList(modifiedList);
  }, [interviewHistory, isDarkMode()]);

  useEffect(() => {
    const modifiedList = interviewHistory?.data?.map((data) => {
      const { InterviewRound } = data;
      const { Interview } = InterviewRound;
      const Type = Interview?.Candidate?.UMap?.length
        ? Interview.Candidate.UMap.map((item) => item.MapT.Technology).join(', ')
        : [];
      const status = INTERVIEW_STATUS_HISTORY.filter(
        (num) => num.code === InterviewRound?.Status
      ).map((item) => item.name)[0];
      return {
        CandidateName: `${
          Interview?.Candidate?.FirstName ? wordCapitalize(Interview.Candidate?.FirstName) : ''
        } ${Interview?.Candidate?.LastName ? wordCapitalize(Interview.Candidate?.LastName) : ''}`,
        HrName: `${
          InterviewRound.InterviewRound.HROfRound?.LastName?.FirstName
            ? wordCapitalize(InterviewRound.InterviewRound.HROfRound?.LastName?.FirstName)
            : ''
        } ${
          InterviewRound.InterviewRound.HROfRound?.LastName?.LastName
            ? wordCapitalize(InterviewRound.InterviewRound.HROfRound?.LastName?.LastName)
            : ''
        }`,

        'Start Date': `${InterviewRound.StartDateOfInterviewRound.substring(0, 10)} ${formatTime(
          InterviewRound.StartDateOfInterviewRound
        )}`,
        EndDateOfInterviewRound: `${InterviewRound.EndDateOfInterviewRound.substring(
          0,
          10
        )} ${formatTime(InterviewRound.EndDateOfInterviewRound)}`,
        Technology: Type,
        RoundOfInterview: `${INTERVIEW_TYPE.filter(
          (num) => num.code === InterviewRound.InterviewRound
        ).map((type) => type.name)}`,
        JobDesignation:
          Interview?.JobDesignation && wordCapitalize(Interview?.JobDesignation?.Designation),

        Status: status
      };
    });
    setHistoryExport(modifiedList);
  }, [interviewHistory]);

  const handlePagination = (pageNumber, size) =>
    setTableStates((prevState) => {
      return {
        ...prevState,
        pageNumber,
        size
      };
    });

  const handleSearch = (searchValue) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        searchValue
      };
    });
  };

  const handleSort = (sortBy) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        sortBy,
        orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
      };
    });
  };

  const handleRowActions = (
    actionType,
    rowId,
    mode,
    type,
    candidateName,
    candidateId,
    roundStatus,
    interviewCategory
  ) => {
    if (actionType === ACTION_TYPE.FEEDBACK) {
      setCandidateName(candidateName);
      setTypeInterview(type);
      setViewFeedbackDetailsStatus(roundStatus);
      setInterviewRoundMode(mode);
      setCandidateId(candidateId);
      setInterviewCategory(interviewCategory);
      if (rowId) {
        setInterviewId(rowId);
        if (type === InterviewType['HR Round']) {
          onToggleHRPopup(true);
        } else {
          onTogglePopup(true);
        }
      }
    }
  };
  return (
    <>
      <AddHrFeedback
        id={interviewRoundId}
        onHide={onToggleHRPopup}
        show={showHRPopup}
        interviewType={typeInterview}
        ByInterviewer
        interviewCategory={interviewCategory}
        disabled={
          viewFeedbackDetailsStatus === STATUS_INTERVIEW.CANCELLED ||
          (userDetails?.FeedbackUpdatedByHRAt &&
            moment().isAfter(moment(userDetails?.FeedbackUpdatedByHRAt).endOf('day')))
        }
        feedbackData={{
          interviewId: userDetails?.Interview?.ID || '',
          feedbackNote: `${userDetails?.FeedbackNote || ''}`,
          overallFeedback: `${userDetails?.OverAllFeedback || ''}`,
          overallGrade: `${userDetails?.OverAllGrades || ''}`,
          jobDesignation: userDetails?.Designation,
          package: userDetails?.Package,
          yearsOfExperience: userDetails?.YearsOfExperience,
          monthsOfExperience: userDetails?.MonthsOfExperience,
          candidateExpectation: userDetails?.CandidateExpectations,
          result: userDetails?.Result
        }}
        confirmDialogMessage={
          <>
            Are you sure you want to submit the feedback for the{' '}
            <strong>{getCandidateName ? wordCapitalize(getCandidateName) : ''}</strong> candidate?
          </>
        }
      />

      <AddEditFeedback
        id={interviewRoundId}
        onHide={onTogglePopup}
        show={showPopup}
        interviewType={typeInterview}
        disabled={
          viewFeedbackDetailsStatus === STATUS_INTERVIEW.CANCELLED ||
          (userDetails?.FeedbackUpdatedByInterviewerAt &&
            moment().isAfter(moment(userDetails?.FeedbackUpdatedByInterviewerAt).endOf('day')))
        }
        interviewRoundMode={interviewRoundMode}
        candidateId={getCandidateId || ''}
        interviewRoundId={interviewRoundId}
        interviewCategory={interviewCategory}
        candidateName={getCandidateName}
        confirmDialogMessage={
          <>
            Are you sure you want to update the feedback for the{' '}
            <strong>{getCandidateName ? wordCapitalize(getCandidateName) : ''}</strong>
            {''} candidate?
          </>
        }
      />
      <DataTable
        name={TABLE_NAME.INTERVIEW_HISTORY}
        columns={HISTORY_INTERVIEW_COLUMNS}
        data={historyList}
        searchPlaceHolder="Search by Candidate name, Email and Mobile"
        exportData={historyExport}
        totalRecords={interviewHistory?.pagination?.totalRecords}
        onPagination={handlePagination}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        onSort={handleSort}
      />
    </>
  );
};

export default InterviewHistory;
