import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

// Components
import CustomDatePicker from '../../components/CustomDatePicker';
import Label from '../../components/Label';
import CustomButton from '../../components/CustomButton';
import AvailabilityDetails from '../../components/popup/AvailabilityDetails';
import { AvailabilityButton, ResumeButton } from '../../components/ActionButtons';
import { TextAreaWithCounterLimit } from '../../components/TextAreaWithCounterLimit';

// date-fns
import addMinutes from 'date-fns/addMinutes';

// Prime-React Components
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { classNames } from 'primereact/utils';
import { AutoComplete } from 'primereact/autocomplete';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Sidebar } from 'primereact/sidebar';

// Constants
import {
  INTERVIEW_TYPE,
  BUTTON_TYPES,
  LABEL_TYPES,
  MSG_TYPES,
  TEXTAREA_LIMIT,
  InterviewType,
  InterviewMode,
  INTERVIEW_MODE,
  RENDER,
  INTERVIEW_CATEGORY,
  Interview_Category,
  queryParamsOfFeedbackParams
} from '../../constants/common';
import { INTERVIEW_DURATION, NUMBER_OF_INTERVIEWERS } from '../../constants/dropdownOptions';

// Redux-Actions
import { allInterviewers } from '../../redux/actions/showOptions';
import { getCandidateBySearch, getCandidate } from '../../redux/actions/candidates';
import { addInterviews, editInterviews, getInterviewDetails } from '../../redux/actions/interviews';
import {
  getInterviewTechnology,
  getJobDesignation,
  getJobDesignationBySearch
} from '../../redux/actions/hr';
import { getFilterCommonParamsList } from '../../redux/actions/commonParameter';

// Redux-Slices
import { apiStatusClear } from '../../redux/slices/apiStatus';
import { updateData } from '../../redux/slices/interviews';

// Utils
import {
  filterFeedbackParams,
  formatAvailabilityTime,
  openResumeLink,
  resumeValidation,
  wordCapitalize
} from '../../utils/common';
import { Badge } from 'primereact/badge';

const pathBase = `${process.env.REACT_APP_API_ENDPOINT}/public/uploads/resume`;

const AddEditInterviews = (props) => {
  const { onHide, show, ID } = props;
  const timeObject = new Date();

  // React useRef hook.
  const fileRef = useRef(null);
  const selectedInterviewer = useRef([]);

  // Redux hooks.
  const dispatch = useDispatch();
  const { InterviewDetails } = useSelector((state) => state.interviews);
  const { filterParameters } = useSelector((state) => state.parameter);
  const { options, candidates } = useSelector((state) => state);
  const { isSucceed, isFailed, isLoading } = useSelector((state) => state.apiStatus);
  const { jobDesignationData, interviewTechnologyType, searchData } = useSelector(
    (state) => state.hrs
  );

  // React router dom hooks.
  const { search } = useLocation();

  const queryparams = search && search?.split('&');
  const interviewerId = queryparams && queryparams[0]?.split('=')[1];
  const startDateTIme =
    queryparams && new Date(queryparams[1]?.split('=')[1] + ' ' + queryparams[2]?.split('=')[1]);
  const interviewStartTime =
    startDateTIme || new Date(timeObject.setMinutes(timeObject.getMinutes() + 30));

  // React useState hooks.
  const [fileName, setFileName] = useState('');
  const [startDate, setStartDate] = useState(interviewStartTime);
  const [isFile, setIsFile] = useState('');
  const [technologies, setTechnologies] = useState('');
  const [interviewerOptions, setInterviewerOptions] = useState(null);
  const [candidateOptions, setCandidateOptions] = useState([]);
  const [feedbackParamsOptions, setFeedbackParamsOptions] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [designationText, setDesignationText] = useState('');
  const [designationOptions, setdesignationOptions] = useState(null);
  const [techSearchText, setTechSearchText] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isUpdatedSuccess, setIsUpdatedSuccess] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  // const [isDisableCadidate, setIsDisableCandidate] = useState(null);
  const [interviewData, setInterviewData] = useState({
    candidateID: '',
    interviewerID: [],
    feedbackParameterIds: [],
    interviewType: '',
    interviewCategory: Interview_Category.Technical,
    interviewRoundMode: InterviewMode['Tatvasoft Office'],
    interviewStartDateTime: new Date(),
    jobDesignation: '',
    resumeFilePath: '',
    interviewerLimit: NUMBER_OF_INTERVIEWERS[0],
    duration: 60,
    description: '',
    isEdit: true,
    isRescheduleEnabled: false
  });

  const onTogglePopup = () => setShowPopup((prevState) => !prevState);

  const handleSearch = () => {
    if (searchText) {
      const sanitizeString = searchText.trim().toString();
      dispatch(getCandidateBySearch(sanitizeString));
    }
  };
  const searchDesignation = () => {
    if (designationText) {
      const sanitizeString = designationText.trim().toString();
      dispatch(getJobDesignationBySearch(sanitizeString));
    }
  };

  useEffect(() => {
    const modifiedDesignationOptions =
      searchData && searchData?.length > 0 ? searchData : [{ Designation: MSG_TYPES.NO_RESULT }];
    setdesignationOptions(modifiedDesignationOptions);
  }, [searchData]);

  useEffect(() => {
    if (!designationText) {
      const modifiedDesiganationOptions =
        jobDesignationData &&
        jobDesignationData.find((designation) => designation.ID === interviewData.jobDesignation);
      setDesignationText(modifiedDesiganationOptions);
    }
  }, [jobDesignationData, interviewData]);

  useEffect(() => {
    if (!techSearchText) {
      const modifiedTechnologyOptions =
        interviewTechnologyType &&
        interviewTechnologyType.find(
          (technology) => technology.ID === interviewData.interviewTechnology
        );
      setTechSearchText(modifiedTechnologyOptions);
    }
  }, [interviewTechnologyType, interviewData]);

  useEffect(() => {
    dispatch(getJobDesignation());
    dispatch(getInterviewTechnology());
  }, [dispatch]);

  useEffect(() => {
    if (ID && InterviewDetails && Object.keys(InterviewDetails)?.length !== 0) {
      setInterviewData({
        isEdit: false,
        interviewCategory: InterviewDetails?.InterviewCategory,
        jobDesignation: InterviewDetails?.JobDesignation && InterviewDetails?.JobDesignation?.ID
      });
      if (InterviewDetails?.Candidate?.ResumeOfCandidate?.FilePath) {
        setIsFile(InterviewDetails?.Candidate?.ResumeOfCandidate?.FilePath);
      } else {
        setIsFile('');
      }
    }
  }, [InterviewDetails, interviewTechnologyType, jobDesignationData, ID]);

  const modifiedOptions = (options) =>
    options?.map((opt) => {
      return {
        ...opt,
        name: `${opt.FirstName} ${opt.LastName}`,
        code: `${opt.ID}`,
        key: `${opt.ID}`,
        disabled: !!opt.InterviewOfCandidate?.length
      };
    });

  const interviewersOptions = (options) => {
    if (!options?.length) return [];
    return options?.map((opt) => {
      return {
        ...opt,
        name: `${opt.FirstName} ${opt.LastName}`,
        code: `${opt.ID}`,
        key: `${opt.ID}`,
        disabled: !opt.IsAvailable,
        isAvailable: opt.IsAvailable
      };
    });
  };

  useEffect(() => {
    const modifiedInterviewerOptions = interviewersOptions(options);
    setInterviewerOptions(modifiedInterviewerOptions);
  }, [options]);

  useEffect(() => {
    if (formik.values.interviewerID?.length > 0) {
      // Get interviewer id list.
      const interviewerOptionsCodeList = interviewerOptions.map((data) => data.code);
      // Removed the selected values which does not exist in the options.
      const filteredSelectedValues = formik.values.interviewerID.filter(
        (value) => interviewerOptionsCodeList.indexOf(value) > -1
      );
      formik.setFieldValue('interviewerID', filteredSelectedValues);
    }
    if (interviewerOptions && interviewerOptions?.length && interviewerId) {
      formik.setFieldValue('interviewerID', [interviewerId]);
    }
  }, [interviewerOptions, interviewerId]);

  const interviewerTemplate = (option) => {
    return (
      <>
        <ReactTooltip />
        <div data-tip={`${formatAvailabilityTime(option.InterviewerToAvailability)}`}>
          {!option.isAvailable ? (
            <span className="unavailable-interviewer-text-color mr-1">
              {wordCapitalize(option.name)}
            </span>
          ) : (
            <span className="mr-1">{wordCapitalize(option.name)}</span>
          )}
          {!!option?.UMap?.length && (
            <span className={`${!option.isAvailable && 'unavailable-interviewer-text-color'} mr-1`}>
              [{option?.UMap?.map((tech) => wordCapitalize(tech?.MapT?.Technology)).join(', ')}]
            </span>
          )}
          {!option.isAvailable && (
            <>
              {formatAvailabilityTime(option.InterviewerToAvailability) && (
                <span className="unavailable-interviewer-text-color mr-1">{`[${formatAvailabilityTime(
                  option.InterviewerToAvailability
                )}]`}</span>
              )}
              {/* <i
                className="pi pi-exclamation-triangle unavailable-interviewer-text-color"
                data-tip="Might not be available for the above schedule selected"
              /> */}
            </>
          )}
          {!!option?.IsPracticalAdmin && (
            <Badge value="Practical Admin" className="practical-admin" severity="success"></Badge>
          )}
        </div>
      </>
    );
  };

  useEffect(() => {
    const modifiedCandidateOptions =
      candidates.searchData && candidates.searchData?.length > 0
        ? modifiedOptions(candidates.searchData)
        : [{ name: MSG_TYPES.NO_RESULT }];
    setCandidateOptions(modifiedCandidateOptions);
  }, [candidates]);

  const onDateChange = (value) => {
    setStartDate(value);
  };

  const clearData = () => {
    onHide(false);
    document.body.classList.remove('sidebar-open');
    setInterviewData({
      candidateID: '',
      interviewerID: [],
      feedbackParameterIds: [],
      interviewType: '',
      interviewCategory: Interview_Category.Technical,
      interviewRoundMode: InterviewMode['Tatvasoft Office'],
      interviewStartDateTime: new Date(),
      jobDesignation: '',
      resumeFilePath: '',
      interviewerLimit: NUMBER_OF_INTERVIEWERS[0],
      duration: 60,
      description: '',
      isEdit: true,
      isRescheduleEnabled: false
    });
    setFileName('');
    setIsFile('');
    setSearchText('');
    setDesignationText('');
    setTechnologies('');
    formik.resetForm();
  };

  useEffect(() => {
    if (show && isUpdatedSuccess && isSucceed) {
      dispatch(updateData());
      dispatch(apiStatusClear());
      clearData();
      setIsUpdatedSuccess(false);
    }
    if (isFailed) {
      setIsUpdatedSuccess(false);
    }
  }, [isFailed, isSucceed]);

  useEffect(() => () => setIsUpdatedSuccess(false), []);

  const formik = useFormik({
    initialValues: interviewData,
    validationSchema: Yup.object({
      isEdit: Yup.boolean(),
      candidateID: Yup.string().when('isEdit', {
        is: true,
        then: Yup.string().required('Candidate is required.')
      }),
      interviewType: Yup.string().when('isEdit', {
        is: true,
        then: Yup.string().required('Interview Type is required.')
      }),
      interviewRoundMode: Yup.string().when('isEdit', {
        is: true,
        then: Yup.string()
      }),
      interviewerID: Yup.array().when(['isEdit'], {
        is: (isEdit) => isEdit,
        then: Yup.array().min(1, 'Interviewer is required.').required('Interviewer is required.')
      }),
      feedbackParameterIds: Yup.array().when(['isEdit', 'interviewType'], {
        is: (isEdit, interviewType) =>
          isEdit &&
          (interviewType === InterviewType['Technical Interview - 1'].toString() ||
            interviewType === InterviewType['Technical Interview - 2'].toString() ||
            interviewType === InterviewType['Management Round'].toString()),
        then: Yup.array()
          .min(1, 'Feedback Parameter is required.')
          .required('Feedback Parameter is required.')
      }),
      interviewCategory: Yup.string(),
      duration: Yup.number().when('isEdit', {
        is: true,
        then: Yup.number().typeError('Interview duration is required.')
      }),
      interviewerLimit: Yup.number().when(['isEdit'], {
        is: (isEdit) => isEdit,
        then: Yup.number()
          .min(1, 'Minimum Limit 1')
          .max(10, 'Maximum  Limit 10')
          .typeError('Only numbers are allowed.')
          .required('Interviewer Limit is required.')
      }),
      jobDesignation: Yup.string().nullable(),
      description: Yup.string()
        .trim()
        .max(TEXTAREA_LIMIT.MAX, 'Designation should not exceed ${max} characters.')
        .nullable(),
      resumeFilePath: Yup.mixed().test(
        'format',
        'Only the following formats are accepted: .doc, .docx, .pdf',
        (value) => {
          if (!value) {
            return true;
          }
          return resumeValidation(value);
        }
      )
    }),

    onSubmit: (values) => {
      try {
        let selectedFeedbackParameterIds = filterFeedbackParams(
          values.feedbackParameterIds,
          feedbackParamsOptions
        );

        const formData = {
          ...(values.interviewerID &&
            values.interviewerID?.length && { interviewerID: values.interviewerID }),
          ...(selectedFeedbackParameterIds && {
            feedbackParameters: selectedFeedbackParameterIds
          }),
          interviewType: values.interviewType,
          interviewCategory: values.interviewCategory,
          interviewRoundMode: values.interviewRoundMode,
          interviewStartDateTime: startDate,
          interviewEndDateTime: addMinutes(startDate, values.duration),
          interviewDuration: values.duration,
          interviewerLimit: values.interviewerLimit,
          description: values.description?.trim(),
          ...(values.jobDesignation && { jobDesignation: values.jobDesignation })
        };

        const formEditData = {
          ...(values.jobDesignation && { jobDesignation: values.jobDesignation })
        };

        let postData = new FormData();
        Object.keys(formData).forEach((el) => {
          if (el === 'feedbackParameters') {
            postData.append('feedbackParameters', JSON.stringify(formData[el]));
          } else if (typeof el === 'string') {
            postData.append(el, formData[el]);
          } else {
            postData.append(el, JSON.stringify(formData[el]));
          }
        });

        let postDataEdit = new FormData();
        Object.keys(formEditData).forEach((el) => {
          if (typeof el === 'string') {
            postDataEdit.append(el, formEditData[el]);
          } else {
            postDataEdit.append(el, JSON.stringify(formEditData[el]));
          }
        });

        !ID && postData.append('candidateID', values.candidateID);
        ID && postData.append('isReschedule', values.isRescheduleEnabled);
        fileName && postData.append('resumeFilePath', values.resumeFilePath);
        ID && fileName && postDataEdit.append('resumeFilePath', values.resumeFilePath);
        ID && postDataEdit.append('interviewCategory', values.interviewCategory);

        const dispachOnSubmit = () => {
          if (ID) {
            dispatch(
              editInterviews({
                postDataEdit,
                interviewId: ID
              })
            );
          } else {
            dispatch(addInterviews(postData));
          }
        };
        const confirmBox = () => {
          confirmDialog({
            message: 'Are you sure you want to schedule an interview without a Resume?',
            header: 'Resume Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              dispachOnSubmit();
            }
          });
        };

        if (
          !InterviewDetails?.Candidate?.ResumeOfCandidate?.FilePath &&
          !fileName &&
          !searchText?.ResumeOfCandidate?.FilePath
        ) {
          confirmBox();
        } else {
          dispachOnSubmit();
        }
      } catch (error) {
        console.log(error);
      }
      setIsUpdatedSuccess(true);
    },
    enableReinitialize: true
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  // React useEffect hooks.
  useEffect(() => {
    ID && dispatch(getInterviewDetails(ID));
    !ID && !startDate && dispatch(getCandidate());
    if (!ID) {
      if (startDate) {
        const endDate = addMinutes(startDate, interviewData.duration);
        const interviewStartTime = moment(startDate).format();
        const interviewEndTime = moment(endDate).format();
        dispatch(
          allInterviewers(
            interviewStartTime,
            interviewEndTime,
            technologies?.length ? technologies : '',
            '',
            formik.values.interviewType === InterviewType['HR Round'],
            formik.values.interviewType === InterviewType.Practical
          )
        );
      }
    }
  }, [
    dispatch,
    ID,
    startDate,
    technologies,
    show,
    formik.values.interviewType,
    formik.values.candidateID
  ]);

  useEffect(() => {
    if (show) {
      dispatch(getFilterCommonParamsList(queryParamsOfFeedbackParams));
    }
  }, [show]);

  const feedBackParamsTemplate = (option) => {
    return (
      <>
        <span className="mr-1">{wordCapitalize(option.name)}</span>
        {!!option?.childParams?.length && (
          <span>
            [{option?.childParams?.map((tech) => wordCapitalize(tech?.parameterName)).join(', ')}]
          </span>
        )}
      </>
    );
  };

  const feedbackParamsOptionsGenerate = (options) => {
    if (!options?.length) return [];

    const committedSingleParams = {
      single: new Set(),
      group: new Set()
    };
    const selectedParameterIds = new Set(formik.values.feedbackParameterIds);

    // Collect committed single parameters
    options.forEach((opt) => {
      if (selectedParameterIds.has(opt.ID)) {
        if (opt.GroupParameters?.length) {
          opt.GroupParameters.forEach((child) =>
            committedSingleParams.group.add(child.Parameter.ID)
          );
        } else {
          committedSingleParams.single.add(opt.ID);
        }
      }
    });

    // Generate parameter options
    const paramsOptions = options.map((opt) => ({
      name: opt.ParameterName,
      code: opt.ID,
      key: opt.ID,
      childParams:
        opt.GroupParameters?.map((child) => ({
          id: child.Parameter.ID || '',
          parameterName: child.Parameter.ParameterName || ''
        })) || [],
      disabled: isOptionDisabled(opt, selectedParameterIds, committedSingleParams)
    }));

    return paramsOptions;
  };

  // Helper function to determine if an option is disabled
  const isOptionDisabled = (opt, selectedParameterIds, committedSingleParams) => {
    if (selectedParameterIds.has(opt.ID)) {
      if (opt.GroupParameters?.length) {
        opt.GroupParameters.forEach((child) => {
          if (selectedParameterIds.has(child.Parameter.ID)) {
            // Remove the child ID from selectedParameterIds
            selectedParameterIds.delete(child.Parameter.ID);
            formik.setFieldValue('feedbackParameterIds', [...selectedParameterIds]);
          }
        });
      }
      return false;
    }

    if (opt.GroupParameters?.length) {
      return opt.GroupParameters.some((child) =>
        committedSingleParams.group.has(child.Parameter.ID)
      );
    }

    return committedSingleParams.single.has(opt.ID) || committedSingleParams.group.has(opt.ID);
  };

  useEffect(() => {
    const modifiedOptions = feedbackParamsOptionsGenerate(filterParameters || []);
    setFeedbackParamsOptions(modifiedOptions);
  }, [filterParameters, formik.values.feedbackParameterIds]);

  // React useEffect hooks.
  useEffect(() => {
    if (startDate) {
      formik.setFieldValue('interviewerID', []);
    }
  }, [formik.values.interviewType, startDate]);

  const candidateTemplate = (option) => {
    // if (option?.disabled) {
    //   setIsDisableCandidate(option);
    // }
    return (
      <div
        id={option.ID}
        // disabled={option?.disabled}
        style={{ opacity: `${option?.disabled && '0.5'}` }}>
        <ReactTooltip />
        <span>{wordCapitalize(option.name)}</span>
        <div className="candidate-list-email-text">{option.Email}</div>
      </div>
    );
  };

  // useEffect(() => {
  //   const disabledDivs = document.querySelectorAll('ul li > div[disabled]');
  //   const allLiTags = document.querySelectorAll('ul li');

  //   // Remove p-disabled class from all li tags
  //   allLiTags.forEach((li) => {
  //     li.classList.remove('p-disabled');
  //   });

  //   // Add p-disabled class to the parent of the relevant div elements
  //   setTimeout(() => {
  //     disabledDivs.forEach((div) => {
  //       const parentLi = div.closest('li');
  //       if (parentLi) {
  //         parentLi.classList.add('p-disabled');
  //       }
  //     });
  //   }, 100);
  // }, [isDisableCadidate]);

  return (
    <>
      <AvailabilityDetails onHide={onTogglePopup} show={showPopup} how />
      <ConfirmDialog />
      <Sidebar
        ID={ID}
        visible={show}
        onHide={() => {
          onHide(true);
          document.body.classList.remove('sidebar-open');
        }}
        position="right"
        className="sidebar-drawer">
        <div className="form-box-wrapper">
          <div className="title-wrapper">
            <p className="card-title">{ID ? 'Edit' : 'Schedule'} Interview</p>
            <button
              className="p-sidebar-close"
              onClick={() => {
                clearData();
              }}>
              <span className="p-sidebar-close-icon pi pi-times" />
            </button>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="p-fluid"
            autoComplete="off"
            encType="multipart/form-data">
            <div className="form-row-wrapper">
              {!ID ? (
                <>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label htmlFor="candidateID" text={LABEL_TYPES.CANDIDATE} isMandatory />
                        {ID ? (
                          <AutoComplete disabled value={interviewData?.candidateName} />
                        ) : (
                          <AutoComplete
                            field="name"
                            name="candidateID"
                            id="candidateID"
                            value={searchText}
                            suggestions={candidateOptions}
                            completeMethod={handleSearch}
                            onChange={(e) => {
                              if (!e.value.disabled) {
                                setSearchText(e.target.value);
                                formik.setFieldValue('candidateID', e.target.value?.code);
                                setTechnologies(
                                  e.target?.value?.UMap?.map((tech) => tech.MapT.ID) || ''
                                );
                              }
                            }}
                            itemTemplate={candidateTemplate}
                            onBlur={formik.handleBlur}
                            placeholder="Select Candidate *"
                            className={classNames({
                              'p-invalid': isFormFieldValid('candidateID')
                            })}
                          />
                        )}
                      </div>
                      {getFormErrorMessage('candidateID')}
                    </div>
                  </div>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="interviewType"
                          text={LABEL_TYPES.INTERVIEW_TYPE}
                          isMandatory
                        />
                        <Dropdown
                          name="interviewType"
                          id="interviewType"
                          optionLabel="name"
                          optionValue="code"
                          value={formik.values.interviewType}
                          options={INTERVIEW_TYPE}
                          onChange={(e) => {
                            formik.handleChange(e);
                            if (isFirstTime && technologies?.length) {
                              formik.setFieldValue('feedbackParameterIds', technologies || []);
                            } else {
                              formik.setFieldValue('feedbackParameterIds', []);
                            }
                          }}
                          placeholder="Select Interview Type *"
                          panelClassName="custom-dropdown-panel"
                          className={classNames({
                            'p-invalid': isFormFieldValid('interviewType')
                          })}
                        />
                      </div>
                      {getFormErrorMessage('interviewType')}
                    </div>
                  </div>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="interviewCategory"
                          text={LABEL_TYPES.INTERVIEW_CATEGORY}
                          isBold
                          isMandatory
                        />
                        <Dropdown
                          id="interviewCategory"
                          name="interviewCategory"
                          value={formik.values.interviewCategory}
                          optionLabel="name"
                          optionValue="code"
                          options={INTERVIEW_CATEGORY}
                          onChange={formik.handleChange}
                          placeholder="Select Interview Category *"
                          panelClassName="custom-dropdown-panel"
                          className={classNames({
                            'p-invalid': isFormFieldValid('interviewCategory'),
                            'w-full': true
                          })}
                        />
                      </div>
                      {getFormErrorMessage('interviewCategory')}
                    </div>
                  </div>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="interviewRoundMode"
                          text={LABEL_TYPES.INTERVIEW_ROUND_MODE}
                          isMandatory
                        />
                        <Dropdown
                          name="interviewRoundMode"
                          id="interviewRoundMode"
                          optionLabel="name"
                          optionValue="code"
                          value={formik.values.interviewRoundMode}
                          options={INTERVIEW_MODE}
                          onChange={formik.handleChange}
                          placeholder="Select Interview Mode"
                          panelClassName="custom-dropdown-panel"
                          className={classNames({
                            'p-invalid': isFormFieldValid('interviewRoundMode')
                          })}
                        />
                      </div>
                      {getFormErrorMessage('interviewRoundMode')}
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              {RENDER.No && (
                <div className="form-col full-width">
                  <div className="form-group-outer">
                    <div className="custom-form-group">
                      <Label htmlFor="jobDesignation" text={LABEL_TYPES.DESIGNATION} isMandatory />
                      <AutoComplete
                        field="Designation"
                        name="jobDesignation"
                        id="jobDesignation"
                        forceSelection
                        value={designationText}
                        suggestions={designationOptions}
                        completeMethod={searchDesignation}
                        onChange={(e) => {
                          setDesignationText(e.target.value);
                          formik.setFieldValue('jobDesignation', e.target.value?.ID);
                        }}
                        onBlur={formik.handleBlur}
                        placeholder="Select Designation"
                        className={classNames({
                          'p-invalid': isFormFieldValid('jobDesignation')
                        })}
                      />
                    </div>
                    {getFormErrorMessage('jobDesignation')}
                  </div>
                </div>
              )}

              {!ID ? (
                <>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <div className="flex">
                          <CustomDatePicker
                            value={startDate}
                            onChange={onDateChange}
                            minDateTime={new Date().setDate(new Date().getDate())}
                            minTime={new Date().setHours(0, 0, 0)}
                            maxTime={new Date().setHours(23, 59, 59)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="duration"
                          text={LABEL_TYPES.INTERVIEW_DURATION}
                          isMandatory
                          isBold
                        />
                        <Dropdown
                          id="duration"
                          name="duration"
                          value={formik.values.duration}
                          options={INTERVIEW_DURATION}
                          optionLabel="name"
                          optionValue="code"
                          onChange={formik.handleChange}
                          panelClassName="custom-dropdown-panel"
                          placeholder="Select Interview Duration"
                        />
                      </div>
                      {getFormErrorMessage('duration')}
                    </div>
                  </div>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="interviewerLimit"
                          text={LABEL_TYPES.INTERVIEWER_LIMIT}
                          isChildren
                          isMandatory
                          isBold
                          tooltip="Sets the maximum no. of interviewers can join the interview."
                        />
                        <Dropdown
                          id="interviewerLimit"
                          name="interviewerLimit"
                          value={formik.values.interviewerLimit}
                          options={NUMBER_OF_INTERVIEWERS}
                          onChange={formik.handleChange}
                          panelClassName="custom-dropdown-panel"
                          placeholder="Select Number of Interviewers"
                        />
                      </div>
                      {getFormErrorMessage('interviewerLimit')}
                    </div>
                  </div>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="interviewerIDs"
                          text={LABEL_TYPES.INTERVIEWERS}
                          isMandatory
                          isBold
                        />
                        <AvailabilityButton onClick={onTogglePopup} />
                        <MultiSelect
                          name="interviewerID"
                          id="interviewerID"
                          optionLabel="name"
                          optionValue="code"
                          value={formik.values.interviewerID}
                          onChange={(e) => {
                            formik.handleChange(e);
                            selectedInterviewer.current.value = e.target.value;
                          }}
                          options={interviewerOptions}
                          itemTemplate={interviewerTemplate}
                          placeholder="Select Interviewer"
                          filter
                          display="chip"
                          className={classNames({
                            'p-invalid': isFormFieldValid('interviewerID')
                          })}
                        />
                      </div>
                      {getFormErrorMessage('interviewerID')}
                    </div>
                  </div>
                  {(formik.values.interviewType === InterviewType['Technical Interview - 1'] ||
                    formik.values.interviewType === InterviewType['Technical Interview - 2'] ||
                    formik.values.interviewType === InterviewType['Management Round']) && (
                    <div className="form-col full-width">
                      <div className="form-group-outer">
                        <div className="custom-form-group">
                          <Label
                            htmlFor="feedbackParameterIds"
                            text={LABEL_TYPES.FEEDBACK_PARAMETERS}
                            isMandatory
                            isBold
                          />
                          <MultiSelect
                            name="feedbackParameterIds"
                            id="feedbackParameterIds"
                            optionLabel="name"
                            optionValue="code"
                            value={formik.values.feedbackParameterIds}
                            onChange={(e) => {
                              formik.handleChange(e);
                              setIsFirstTime(false);
                            }}
                            options={feedbackParamsOptions}
                            itemTemplate={feedBackParamsTemplate}
                            placeholder="Select Feedback Params"
                            filter
                            showSelectAll={false}
                            display="chip"
                            className={classNames({
                              'p-invalid': isFormFieldValid('feedbackParameterIds')
                            })}
                          />
                        </div>
                        {getFormErrorMessage('feedbackParameterIds')}
                      </div>
                    </div>
                  )}
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <TextAreaWithCounterLimit
                          placeholder={LABEL_TYPES.DESCRIPTION}
                          labelText={LABEL_TYPES.DESCRIPTION}
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          errorMessage={
                            formik.touched['description'] ? formik.errors['description'] : ''
                          }
                          className={classNames({
                            'p-invalid': isFormFieldValid('description'),
                            'w-full': true
                          })}
                          id="description"
                          name="description"
                          htmlFor="description"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}

              {ID && (
                <div className="form-col full-width">
                  <div className="form-group-outer">
                    <div className="custom-form-group">
                      <Label
                        htmlFor="interviewCategory"
                        text={LABEL_TYPES.INTERVIEW_CATEGORY}
                        isBold
                        isMandatory
                      />
                      <Dropdown
                        id="interviewCategory"
                        name="interviewCategory"
                        value={formik.values.interviewCategory}
                        optionLabel="name"
                        optionValue="code"
                        options={INTERVIEW_CATEGORY}
                        onChange={formik.handleChange}
                        panelClassName="custom-dropdown-panel"
                        className={classNames({
                          'p-invalid': isFormFieldValid('interviewCategory'),
                          'w-full': true
                        })}
                      />
                    </div>
                    {getFormErrorMessage('interviewCategory')}
                  </div>
                </div>
              )}

              <div className="form-col full-width">
                <div className="form-group-outer upload-document-wrapper">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="resumeFilePath"
                      text={!ID ? LABEL_TYPES.UPLOAD_RESUME : LABEL_TYPES.UPDATE_RESUME}
                      isBold
                    />
                    <input
                      hidden
                      ref={fileRef}
                      type="file"
                      id="resumeFilePath"
                      name="resumeFilePath"
                      onChange={(e) => {
                        formik.setFieldValue('resumeFilePath', e.target.files[0]);
                        setFileName(e.target.files[0].name);
                        setIsFile('');
                      }}
                      accept=".doc, .docx, .pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      className={classNames({
                        'p-invalid': isFormFieldValid('resumeFilePath')
                      })}
                    />
                  </div>
                  <div className="flex flex-wrap align-items-center">
                    <CustomButton
                      variant="contained"
                      className="gray-btn"
                      onClick={() => {
                        fileRef.current.click();
                      }}>
                      <div>
                        <i className="pi pi-fw pi-upload mr-1" />
                        <span>{!ID ? BUTTON_TYPES.UPLOAD_RESUME : BUTTON_TYPES.UPDATE_RESUME}</span>
                      </div>
                    </CustomButton>
                    {searchText?.ResumeOfCandidate?.FilePath ? (
                      !fileName ? (
                        <ResumeButton
                          onClick={() =>
                            openResumeLink(
                              `${pathBase}/${searchText?.ID}/${searchText?.ResumeOfCandidate?.FilePath}`
                            )
                          }
                        />
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                    {ID ? (
                      isFile ? (
                        <ResumeButton
                          onClick={() =>
                            openResumeLink(
                              `${pathBase}/${InterviewDetails?.Candidate?.ID}/${isFile}`
                            )
                          }
                        />
                      ) : (
                        <p>{fileName}</p>
                      )
                    ) : (
                      <p>{fileName}</p>
                    )}
                  </div>
                </div>
                {getFormErrorMessage('resumeFilePath')}
              </div>
            </div>
            <div className="form-btn-wrapper">
              <CustomButton
                type="submit"
                variant="contained"
                disabled={isLoading || formik.values.isRescheduleEnabled}>
                {BUTTON_TYPES.SAVE}
              </CustomButton>

              <CustomButton
                variant="contained"
                onClick={() => {
                  clearData();
                }}
                color="error"
                className="gray-btn">
                {BUTTON_TYPES.CANCEL}
              </CustomButton>
            </div>
          </form>
        </div>
      </Sidebar>
    </>
  );
};

export default AddEditInterviews;
