import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import DataTable from '../../components/DataTable';
import { ActionButtonWithoutView } from '../../components/ActionButtons';

// Prime-React Components
import { confirmDialog } from 'primereact/confirmdialog';

// Redux-Actions
import {
  deleteCommonParames,
  getCommonParamsById,
  getCommonParamsList
} from '../../redux/actions/commonParameter';

// Constants
import {
  ACTION_TYPE,
  DEBOUNCE_TIMEOUT,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  TABLE_NAME
} from '../../constants/common';
import { COMMON_PARAMS_COLUMNS } from '../../constants/tableColumns';

// Utils
import { debounce } from '../../utils/debounce';
import { getTechnologyColorByIndex, isDarkMode, wordCapitalize } from '../../utils/common';
import AddEditCommonParams from './AddEditCommonParams';
import { Chip } from '@mui/material';

const CommonParameters = () => {
  const dispatch = useDispatch();
  const { parameter } = useSelector((state) => state);
  const [parameterList, setParameterList] = useState([]);
  const [parameterId, setParameterId] = useState(null);
  const [tableStates, setTableStates] = useState({
    searchValue: '',
    pageNumber: INITIAL_PAGE_NUMBER,
    size: INITIAL_SIZE,
    sortBy: 'DateModified',
    orderBy: 'desc'
  });

  const [visibleRight, setVisibleRight] = useState(false);
  const onToggleSiderbar = (visibleRight) => setVisibleRight(visibleRight);

  useEffect(() => {
    dispatch(getCommonParamsList(tableStates));
  }, [
    dispatch,
    tableStates,
    parameter?.isTechnologyDelete,
    parameter?.isUpdated,
    parameter?.isAdded
  ]);

  useEffect(() => {
    const modifiedTechnology = parameter?.data.map((parameter) => {
      return {
        ...parameter,
        Name: wordCapitalize(parameter.ParameterName),
        isGroup: (
          <Chip
            label={parameter.GroupParameters?.length ? 'Group' : 'Single'}
            className={parameter.GroupParameters?.length ? 'grouped-params' : 'single-param'}
          />
        ),
        Parameters: !!parameter?.GroupParameters?.length && (
          <ul className="technology-list">
            {parameter.GroupParameters?.map((param, index) => {
              return (
                param.Parameter.ParameterName && (
                  <li
                    key={index}
                    className="technology-badge"
                    style={{
                      backgroundColor: `${
                        isDarkMode()
                          ? getTechnologyColorByIndex(index)?.technologyDarkModeColor
                          : getTechnologyColorByIndex(index)?.technologyColor
                      }`
                    }}>
                    {param.Parameter.ParameterName}
                  </li>
                )
              );
            })}
          </ul>
        ),
        action: (
          <div className="flex align-items-center">
            <ActionButtonWithoutView
              onClick={(actionType) => handleRowActions(actionType, parameter.ID)}
            />
          </div>
        )
      };
    });
    setParameterList(modifiedTechnology);
  }, [parameter, isDarkMode()]);

  const handlePagination = (pageNumber, size) =>
    setTableStates((prevState) => {
      return {
        ...prevState,
        pageNumber,
        size
      };
    });

  const handleSearch = (searchValue) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        searchValue
      };
    });
  };

  const handleSort = (sortBy) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        sortBy,
        orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
      };
    });
  };

  const handleRowActions = (actionType, rowId) => {
    if (actionType === ACTION_TYPE.EDIT) {
      onToggleSiderbar(true);
      setParameterId(rowId);
      dispatch(getCommonParamsById(rowId));
      document.body.classList.add('sidebar-open');
    } else if (actionType === ACTION_TYPE.DELETE) {
      confirmDialog({
        header: 'Delete',
        message: 'Are you sure you want to delete?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => dispatch(deleteCommonParames(rowId))
      });
    }
  };

  const handleAdd = () => {
    setParameterId(null);
    onToggleSiderbar(true);
    document.body.classList.add('sidebar-open');
  };

  return (
    <>
      <AddEditCommonParams ID={parameterId} onHide={onToggleSiderbar} show={visibleRight} />
      <DataTable
        name={TABLE_NAME.COMMON_PRAMETERS}
        searchPlaceHolder="Search by Name"
        columns={COMMON_PARAMS_COLUMNS}
        data={parameterList}
        totalRecords={parameter?.pagination?.totalRecords}
        onPagination={handlePagination}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        onAdd={() => handleAdd()}
        onSort={handleSort}
      />
    </>
  );
};

export default CommonParameters;
